<template>
  <div>
    <department-list></department-list>
  </div>
</template>

<script>

export default {
}
</script>


